import {User} from '@hconnect/apiclient'
import {Box, Button} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {
  selectLoggedInUserPermissions,
  selectLoggedInUserProfile
} from '../../../modules/LoggedInUser.selectors'
import {ResetPasswordDialog} from '../../ManageUser/dialogs/ResetPasswordDialog'
import {useResetPassword} from '../../ManageUser/hooks/useResetPassword'
import {useStyles} from '../QuickPreview.styles'

interface ResetPasswordProps {
  user?: User
}

export const ResetPassword = ({user}: ResetPasswordProps) => {
  const {t} = useTranslation()
  const {classes} = useStyles()

  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)

  const permissions = useSelector(selectLoggedInUserPermissions)

  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false)

  const {handleResetPassword} = useResetPassword({setShowResetPasswordDialog, userData: user})

  const canResetPassword =
    loggedInUserProfile?.id === user?.id ||
    permissions.some((permission) => permission.permissionType === 'CHANGE_USER_LOGIN')

  return (
    <Box mt={2}>
      <Button
        type="button"
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => setShowResetPasswordDialog(true)}
        data-test-id="button-reset-password"
        disabled={!canResetPassword}
        className={classes.resetPasswordButton}
      >
        {t('quickPreview.resetPassword')}
      </Button>

      {user && (
        <ResetPasswordDialog
          showResetPasswordDialog={showResetPasswordDialog}
          setShowResetPasswordDialog={setShowResetPasswordDialog}
          handleResetPassword={handleResetPassword}
        />
      )}
    </Box>
  )
}
