import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {RoleAssignment} from '../../../modules/ManageUsers.selectors'
import {assignUserRole} from '../../WidgetUserCreation/hooks/useCreateWidgetUser'

const assignUserRoles = async (roles: RoleAssignment[]) => {
  for (const userRole of roles) {
    await assignUserRole(userRole)
  }
}
export const useCreateUserRoles = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const {t} = useTranslation()

  return useMutation(assignUserRoles, {
    onSuccess: () => {
      enqueueSnackbar(t('quickPreview.addPermissions.form.successCreateMsg'), {
        anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
        variant: 'success',
        onClick: () => closeSnackbar()
      })
    },
    onError: (e) => {
      const error = e as AxiosError
      enqueueSnackbar(
        error.response?.status === 304
          ? t('quickPreview.addPermissions.form.errorRoleExistsMsg')
          : t('errorboundary.error'),
        {
          anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
          variant: 'error',
          onClick: () => closeSnackbar()
        }
      )
    }
  })
}
