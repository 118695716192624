import {LoadingButton} from '@hconnect/uikit'
import {Close} from '@material-ui/icons'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useOrderChangeConfigsDeleteMutation} from '../hooks/useOrderChangeConfigsMutations'

type DeleteDialogProps = {
  open: boolean
  onClose: () => void
  configurationId: string
  confirmMessage: string
  dialogTitle: string
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  open,
  onClose,
  configurationId,
  confirmMessage,
  dialogTitle
}) => {
  const {t} = useTranslation()
  const deleteMutation = useOrderChangeConfigsDeleteMutation()

  const handleDelete = async () => {
    try {
      await deleteMutation.mutateAsync(configurationId)
      onClose()
    } catch (error) {
      console.error('Failed to delete the configuration:', error)
    }
  }

  return (
    <Dialog
      data-test-id="delete-order-change-configuration-dialog"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <IconButton
          onClick={() => onClose()}
          style={{border: 'none', boxShadow: 'none', marginRight: '10px'}}
          disabled={deleteMutation.isLoading}
          data-test-id="modal-close-button"
        >
          <Close fontSize="medium" />
        </IconButton>
      </Box>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{confirmMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={handleDelete}
          loading={deleteMutation.isLoading}
          variant="text"
          color="primary"
          style={{
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '22px'
          }}
          data-test-id="delete-order-change-configuration-dialog-action-delete"
        >
          {t('configurations.hconnect.orderChange.configurationList.deleteDialogDeleteButton')}
        </LoadingButton>
        <Button
          onClick={onClose}
          color="primary"
          variant="text"
          disabled={deleteMutation.isLoading}
          data-test-id="delete-order-change-configuration-dialog-action-cancel"
        >
          {t('configurations.hconnect.orderChange.configurationList.deleteDialogCancelButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
