import {FormControlLabel, Switch} from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useWidgetUserCreationStyles} from '../WidgetUserCreation.styles'
import {
  CustomerToggleBasedPermissionKeys,
  CustomerToggleBasedPermissions
} from '../WidgetUserCreation.types'

type WidgetPermissionInputProps = {
  permissionKey: CustomerToggleBasedPermissionKeys
  selectedPermissions: CustomerToggleBasedPermissions
  setSelectedPermissions: (selectedPermissions: CustomerToggleBasedPermissions) => void
  defaultValue?: boolean
  disabled?: boolean
}
export const WidgetPermissionInput = ({
  selectedPermissions,
  setSelectedPermissions,
  permissionKey,
  defaultValue,
  disabled
}: WidgetPermissionInputProps) => {
  const {t} = useTranslation()
  const {classes} = useWidgetUserCreationStyles()
  return (
    <FormControlLabel
      value={permissionKey}
      disabled={disabled}
      control={
        <Switch
          color="primary"
          checked={defaultValue || !!selectedPermissions[permissionKey]}
          disabled={disabled}
          onChange={(_, checked) =>
            setSelectedPermissions({
              ...selectedPermissions,
              [permissionKey]: checked
            })
          }
          data-test-id={`widgetUserCreation-${permissionKey}`}
          classes={{
            track: clsx({
              [classes.trackEnabled]: !!selectedPermissions[permissionKey]
            })
          }}
        />
      }
      label={t(`widgetUserCreation.form.actionBasedPermissions.${permissionKey}`)}
      labelPlacement="end"
      data-test-id={`widgetUserCreation-${permissionKey}-label`}
    />
  )
}
