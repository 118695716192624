import {Typography, useTranslation, ResponsiveTable, useBreakPoints} from '@hconnect/uikit'
import {Delete} from '@mui/icons-material'
import {Box, Select, MenuItem, TextField, Button, Paper} from '@mui/material'
import {useMemo} from 'react'

import {OrderChangeConfigOptions, OrderChangeFieldSetting} from '../types/OrderChangeConfig'

import {useStyles} from './OrderChangeFieldSettingsTable.styles'

type Props = {
  fieldSettings: OrderChangeFieldSetting[]
  filterOptionsByCountry: string[]
  configOptions: OrderChangeConfigOptions
  handleAddField: (selectedItem: string) => void
  handleDeleteField: (itemId: string) => void
  handleFieldChange: (index: number, field: string, value: string) => void
}

export const OrderChangeFieldSettingsTable = ({
  fieldSettings,
  filterOptionsByCountry,
  configOptions,
  handleAddField,
  handleDeleteField,
  handleFieldChange
}: Props) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const sortedFieldSettings = useMemo(() => {
    return fieldSettings.sort(
      (a, b) =>
        configOptions.availableFields[a.fieldName].sortKey -
        configOptions.availableFields[b.fieldName].sortKey
    )
  }, [configOptions, fieldSettings])

  const availableFields = useMemo(() => {
    const usedFieldNames = fieldSettings.map((setting) => setting.fieldName)
    return filterOptionsByCountry.filter((fieldName) => !usedFieldNames.includes(fieldName))
  }, [fieldSettings, filterOptionsByCountry])

  const columns = [
    {
      field: 'fieldName',
      headerName: t('configurations.hconnect.orderChange.configurationDetails.fieldName')
    },
    {
      field: 'editLeadTime',
      headerName: t('configurations.hconnect.orderChange.configurationDetails.editLeadTime')
    },
    {
      field: 'leadTimeUom',
      headerName: t('configurations.hconnect.orderChange.configurationDetails.leadTimeUom')
    },
    {field: 'actions', headerName: ''}
  ]

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>
        {t('configurations.hconnect.orderChange.configurationDetails.fieldSettings.title')}
      </Typography>
      <Box className={classes.addFieldBox}>
        <Select
          value=""
          onChange={(e) => handleAddField(e.target.value)}
          displayEmpty
          inputProps={{'aria-label': 'Without label'}}
          className={classes.select}
        >
          <MenuItem value="" disabled>
            {t('configurations.hconnect.orderChange.configurationDetails.fieldSettings.addField')}
          </MenuItem>
          {availableFields.map((key) => (
            <MenuItem key={key} value={key}>
              {configOptions.availableFields[key].fieldName}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Paper>
        <ResponsiveTable
          columns={columns}
          rows={sortedFieldSettings.map((item, index) => ({
            fieldName: configOptions.availableFields[item.fieldName].fieldName,
            editLeadTime: (
              <TextField
                type="number"
                value={item.editLeadTime}
                onChange={(e) => {
                  const value = e.target.value
                  if (Number(value) >= 0) {
                    handleFieldChange(index, 'editLeadTime', value)
                  }
                }}
                size="small"
              />
            ),
            leadTimeUom: (
              <Select
                value={item.editLeadTimeUom}
                onChange={(e) => handleFieldChange(index, 'editLeadTimeUom', e.target.value)}
              >
                {configOptions.leadTimeUnitOfMeasures.map((uom) => (
                  <MenuItem key={uom} value={uom}>
                    {t(`configurations.hconnect.orderChange.configurationDetails.uom.${uom}`)}
                  </MenuItem>
                ))}
              </Select>
            ),
            actions: (
              <Button
                variant="outlined"
                onClick={() => handleDeleteField(item.fieldName)}
                className={classes.deleteButton}
              >
                <Delete />
              </Button>
            )
          }))}
          keyField="fieldName"
          onSort={() => {}}
          isMobile={isMobile}
        />
      </Paper>
    </Box>
  )
}
