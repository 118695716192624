import {Typography} from '@hconnect/uikit'
import {ArrowBackIos} from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import {Box, Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

interface HeaderProps {
  userId: string
  isEditing: boolean
  handleFormMode: (edit: boolean) => void
}

export const Header = ({isEditing, handleFormMode}: HeaderProps) => {
  const {t} = useTranslation()
  const history = useHistory<{isUserList?: boolean}>()
  const shouldGoBack = history.location.state?.isUserList

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="start"
        width="100%"
      >
        {shouldGoBack && (
          <Button
            variant="text"
            style={{
              color: '#007837',
              fontSize: '14px',
              fontWeight: 500,
              marginLeft: '-8px',
              padding: '4px 16px'
            }}
            startIcon={<ArrowBackIos style={{color: '#007837', fontSize: '12px'}} />}
            onClick={() => history.goBack()}
          >
            {t('quickPreview.goBack')}
          </Button>
        )}
        <Typography style={{fontSize: '28px', fontWeight: 700, margin: '0 0 24px 0'}}>
          {t('quickPreview.userQuickViewHeader')}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        marginBottom="14px"
      >
        <Typography style={{fontWeight: 'bold'}}>{t('quickPreview.generalData')}</Typography>
        {!isEditing && (
          <Button
            startIcon={<EditIcon style={{color: '#016AD4'}} />}
            variant="text"
            data-test-id="button-edit-userInfo"
            onClick={() => handleFormMode(true)}
            style={{color: '#016AD4', marginRight: '-10px'}}
          >
            {t('manageUser.edit')}
          </Button>
        )}
      </Box>
    </Box>
  )
}
