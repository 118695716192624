import {Typography, useTranslation} from '@hconnect/uikit'
import {Box, Select, MenuItem, TextField, SelectChangeEvent} from '@mui/material'
import React from 'react'
import {Control, Controller} from 'react-hook-form'

import {OrderChangeConfig} from '../types/OrderChangeConfig'

import {useStyles} from './OrderChangeFormFields.styles'

type Props = {
  formMethods: {
    control: Control<OrderChangeConfig>
  }
  formState: OrderChangeConfig
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void,
    fieldName: keyof OrderChangeConfig
  ) => void
  handleCountryChange: (event: SelectChangeEvent<string>) => void
  countries: string[]
  disabled: boolean
}

const DataScopeNames = ['businessLine', 'orgUnitId', 'dispatchGroup', 'soldTo']

export const OrderChangeFormFields = ({
  formMethods,
  formState,
  handleInputChange,
  handleCountryChange,
  countries,
  disabled
}: Props) => {
  const {t} = useTranslation()
  const {classes} = useStyles()

  return (
    <Box display="flex" flexDirection={'column'} gap={2}>
      <Controller
        name="countryCode"
        control={formMethods.control}
        rules={{
          required: t('configurations.hconnect.orderChange.configurationDetails.requiredField', {
            fieldName: 'countryCode'
          })
        }}
        render={({field: controllerField, fieldState}) => (
          <Box>
            <Select
              {...controllerField}
              onChange={(event) => {
                controllerField.onChange(event)
                handleCountryChange(event)
              }}
              fullWidth
              value={formState.countryCode}
              displayEmpty
              inputProps={{'aria-label': 'Country Code'}}
              className={classes.select}
              error={!!fieldState.error}
              disabled={disabled}
            >
              <MenuItem value="" disabled>
                {t('configurations.hconnect.orderChange.configurationDetails.countryCodeLabel')}
              </MenuItem>
              {countries.map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
            {fieldState.error && (
              <Typography className={classes.errorText}>{fieldState.error.message}</Typography>
            )}
          </Box>
        )}
      />
      {DataScopeNames.map((field) => (
        <Controller
          key={field}
          name={field as keyof OrderChangeConfig}
          control={formMethods.control}
          rules={{
            required:
              field === 'businessLine' || field === 'orgUnitId'
                ? t('configurations.hconnect.orderChange.configurationDetails.requiredField', {
                    fieldName: field
                  })
                : false
          }}
          render={({field: controllerField, fieldState}) => (
            <TextField
              {...controllerField}
              fullWidth
              label={t(`configurations.hconnect.orderChange.configurationDetails.${field}Label`)}
              type="text"
              variant="standard"
              className={`${classes.textField} ${fieldState.error ? classes.errorField : ''}`}
              onChange={(event) =>
                handleInputChange(event, controllerField.onChange, field as keyof OrderChangeConfig)
              }
              InputProps={{disableUnderline: true}}
              InputLabelProps={{className: classes.inputLabel}}
              disabled={disabled}
              focused={true}
              error={!!fieldState.error}
              helperText={fieldState.error ? fieldState.error.message : null}
            />
          )}
        />
      ))}
    </Box>
  )
}
