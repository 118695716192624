import {
  Content,
  LoadingButton,
  Page,
  TitleNav,
  useBreakPoints,
  useTranslation
} from '@hconnect/uikit'
import {ArrowBackIos, ArrowForward} from '@mui/icons-material'
import {Box, Grid, Paper, Typography, SelectChangeEvent} from '@mui/material'
import {useForm} from 'react-hook-form'
import {useHistory} from 'react-router'

import {useAllowedHConnectConfigCountries} from '../../hooks/useAllowedHConnectConfigCountries'
import {OrderChangeConfig, OrderChangeConfigOptions} from '../types/OrderChangeConfig'

import {useStyles} from './OrderChangeConfigurationDetails.styles'
import {OrderChangeFieldSettingsTable} from './OrderChangeFieldSettingsTable'
import {OrderChangeFormFields} from './OrderChangeFormFields'

type Props = {
  mode: 'edit' | 'create' | 'copy'
  configId?: string
  onSaveButtonClick: () => void
  formState: OrderChangeConfig
  setFormState: React.Dispatch<React.SetStateAction<OrderChangeConfig>>
  configOptions: OrderChangeConfigOptions
  isSubmitting: boolean
}

export const OrderChangeConfigurationDetails = (props: Props) => {
  const {mode, onSaveButtonClick, formState, setFormState, configOptions, isSubmitting} = props
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const isTablet = ['xs', 'sm', 'md'].includes(screenSizes)
  const {classes} = useStyles({isMobile})
  const history = useHistory()

  const configurableHConnectCountries = useAllowedHConnectConfigCountries()

  const formMethods = useForm<OrderChangeConfig>({
    defaultValues: formState,
    mode: 'onChange'
  })

  const formatTitle = () => {
    switch (mode) {
      case 'edit':
        return t('configurations.hconnect.orderChange.configurationDetails.titleEdit')
      case 'create':
        return t('configurations.hconnect.orderChange.configurationDetails.titleCreate')
      case 'copy':
        return t('configurations.hconnect.orderChange.configurationDetails.titleCopy')
    }
  }

  const filterOptionsByCountry = (countryCode: string) => {
    return Object.keys(configOptions.availableFields).filter((key) =>
      configOptions.availableFields[key].allowedCountries.includes(countryCode)
    )
  }

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    const countryCode = event.target.value
    const allowedFields = filterOptionsByCountry(countryCode)

    setFormState((prevState) => ({
      ...prevState,
      countryCode: countryCode,
      fieldSettings: prevState.fieldSettings.filter((item) =>
        allowedFields.includes(item.fieldName)
      )
    }))
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void,
    fieldName: keyof OrderChangeConfig
  ) => {
    const input = event.target.value
    setFormState({...formState, [fieldName]: input})
    onChange(input)
  }

  const handleAddField = (selectedItem: string) => {
    if (formState.fieldSettings.some((item) => item.fieldName === selectedItem)) {
      return
    }
    setFormState((prevState) => ({
      ...prevState,
      fieldSettings: [
        ...prevState.fieldSettings,
        {
          editLeadTime: 0,
          fieldName: configOptions.availableFields[selectedItem].fieldName,
          editLeadTimeUom: '',
          orderChangeSettingId: ''
        }
      ]
    }))
  }

  const handleDeleteField = (itemFieldName: string) => {
    setFormState((prevState) => ({
      ...prevState,
      fieldSettings: prevState.fieldSettings.filter((item) => item.fieldName !== itemFieldName)
    }))
  }

  const handleFieldChange = (index: number, field: string, value: string) => {
    setFormState((prevState) => ({
      ...prevState,
      fieldSettings: prevState.fieldSettings.map((item, idx) =>
        idx === index ? {...item, [field]: value} : item
      )
    }))
  }

  const onSubmit = (data: OrderChangeConfig) => {
    setFormState(data)
    onSaveButtonClick()
  }

  return (
    <Content>
      <Page
        px={isMobile ? 2 : 6}
        boxed={false}
        title={!isTablet && <Box className={classes.pageTitle}>{formatTitle()}</Box>}
        aboveTitle={
          <Box className={classes.backButton}>
            <TitleNav
              title={
                isTablet
                  ? formatTitle()
                  : t('configurations.hconnect.orderChange.configurationDetails.goBack')
              }
              customTitle={
                !isTablet && (
                  <Box className={classes.pageNav}>
                    <ArrowBackIos />
                    <Typography>
                      {t('configurations.hconnect.orderChange.configurationDetails.goBack')}
                    </Typography>
                  </Box>
                )
              }
              onClick={() => history.goBack()}
              className={classes.pageAboveTitle}
            />
          </Box>
        }
      >
        <Grid container className={classes.gridContainer} spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItem}>
            <Paper elevation={4} className={classes.paper}>
              <OrderChangeFormFields
                formMethods={formMethods}
                formState={formState}
                handleInputChange={handleInputChange}
                handleCountryChange={handleCountryChange}
                countries={configurableHConnectCountries}
                disabled={mode === 'edit'}
              />
            </Paper>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItem}>
            <Paper elevation={4} className={classes.paper}>
              <OrderChangeFieldSettingsTable
                fieldSettings={formState.fieldSettings}
                filterOptionsByCountry={filterOptionsByCountry(formState.countryCode)}
                configOptions={configOptions}
                handleAddField={handleAddField}
                handleDeleteField={handleDeleteField}
                handleFieldChange={handleFieldChange}
              />
              <Box className={classes.saveButtonBox}>
                <LoadingButton
                  startIcon={<ArrowForward fontSize="small" />}
                  variant="contained"
                  color="primary"
                  onClick={formMethods.handleSubmit(onSubmit)}
                  loading={isSubmitting}
                >
                  {mode === 'edit'
                    ? t('configurations.hconnect.orderChange.configurationDetails.buttonSave')
                    : t(
                        `configurations.hconnect.orderChange.configurationDetails.button${
                          mode.charAt(0).toUpperCase() + mode.slice(1)
                        }`
                      )}
                </LoadingButton>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Page>
    </Content>
  )
}
