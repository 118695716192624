import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  container: {
    marginTop: theme.spacing(2)
  },
  title: {
    fontSize: '18px',
    fontWeight: 600
  },
  addFieldBox: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  select: {
    width: '20%'
  },
  deleteButton: {
    '&:hover': {
      color: '#29aaff', 
    }
  }
}))
