import {Country} from '@hconnect/apiclient'
import {Content, Page, Subheader, TitleNav, Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import React, {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory, useLocation, useParams} from 'react-router-dom'

import {api} from '../../App.store'
import {useCountriesConfiguration} from '../../common/hooks/useCountriesConfiguration'
import {useUser} from '../../common/hooks/useUser'
import {useUserCrossCountriesConfiguration} from '../../common/hooks/useUserCrossCountriesConfiguration'
import {useUserRoles} from '../../common/hooks/useUserRoles'
import {UserInformationFormData} from '../../components/UserInformation/UserInformationForm.types'
import {getLiveProducts} from '../../components/UserInformation/utils/utils'
import {selectLoggedInUserProfile} from '../../modules/LoggedInUser.selectors'

import {DeleteUserDialog} from './dialogs/DeleteUserDialog'
import {ResetPasswordDialog} from './dialogs/ResetPasswordDialog'
import {ManageUserHeader} from './header/ManageUserHeader'
import {useDeleteUser} from './hooks/useDeleteUser'
import {useResetPassword} from './hooks/useResetPassword'
import {useUpdateUser} from './hooks/useUpdateUser'
import {InviteUserButtons} from './InviteUserButtons'
import {ManageUserIssues} from './issues/ManageUserIssues'
import {useStyles} from './ManageUser.styles'
import {ManageUserRoles} from './roles/ManageUserRoles'
import {handleUserData} from './utils/handleUserData'

export const ManageUser = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const history = useHistory<{source: string}>()

  const [isEditing, setEditing] = useState(false)
  const [showAutoRoles, setShowAutoRoles] = useState(true)
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false)
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false)
  const {state} = useLocation()
  const {userId} = useParams<{userId: string}>()
  const queryParams = new URLSearchParams(location.search)
  const includeCrossBorderUser = queryParams.get('includeCrossBorderUser') === 'true'


  const {
    data: user,
    refetch: refetchUser,
    isLoading: isUserLoading,
    isError: isUserError
  } = useUser(userId, includeCrossBorderUser)

  const {data: tfaData, isError: isTfaError} = useQuery(
    ['tfa'],
    async () => {
      const response: AxiosResponse = await api.get(`tfa/${userId}`)
      return response
    },
    {retry: false, refetchOnWindowFocus: false}
  )

  const {
    data: roles,
    refetch: refetchRoles,
    isLoading: isRolesLoading,
    isError: isRolesError
  } = useUserRoles(userId)

  const {
    data: userCountries,
    isLoading: isUserCountriesLoading,
    error: isUserCountriesError
  } = useCountriesConfiguration()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)

  const {data: loggedInUserRoles} = useUserRoles(loggedInUserProfile?.user_id)

  const {data: viewCrossCountries} = useUserCrossCountriesConfiguration(
    loggedInUserRoles,
    includeCrossBorderUser
  )

  const [availableCountries, setAvailableCountries] = useState<Country[]>()
  useEffect(() => {
    const allCountries = [
      ...(userCountries || []),
      ...(viewCrossCountries?.filter((x) => x.countryCode === user?.country) || [])
    ]
    const uniqueCountries = allCountries.reduce((acc, current) => {
      if (!acc.some((country) => country.countryCode === current.countryCode)) {
        acc.push(current)
      }
      return acc
    }, [] as Country[])
    setAvailableCountries(uniqueCountries)
  }, [userCountries, viewCrossCountries, user?.country])

  const userForm = useMemo(
    () => ({
      name: user?.name ?? '',
      eMail: user?.eMail ?? '',
      mobileNumber: user?.mobileNumber ?? '',
      companyName: user?.companyName ?? '',
      country: user?.country ?? '',
      availableCountries,
      defaultBranding: user?.defaultBranding ?? '',
      primaryOrganisationalUnit: user?.primaryOrganisationalUnit ?? '',
      marketId:
        (user?.country === 'CA' || user?.country === 'US') && !user?.marketId
          ? 'hproduce'
          : user?.marketId ?? '',
      defaultLocale: user?.defaultLocale ?? '',
      isTester: !!user?.isTester,
      twoFactorEnabled: user?.twoFactorEnabled,
      blocklistNotifications: user?.blocklistNotifications
    }),
    [user, availableCountries]
  )

  const formMethods = useForm<UserInformationFormData>({
    defaultValues: userForm,
    mode: 'all',
    reValidateMode: 'onChange'
  })

  const {handleUpdateUser} = useUpdateUser({setEditing, formMethods, refetchUser, userData: user})
  const {handleDeleteUser} = useDeleteUser({setShowDeleteUserDialog, userData: user})
  const {handleResetPassword} = useResetPassword({setShowResetPasswordDialog, userData: user})

  useEffect(() => {
    userForm && formMethods.reset(userForm)
  }, [formMethods, userForm, availableCountries])

  const {accountNumber, extraInfo} = handleUserData(user)

  const liveProducts = getLiveProducts(userCountries ?? null, user)

  const isError = isUserError || isRolesError || isUserCountriesError

  if (isError) {
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        {t('error.notFound')}
      </Box>
    )
  }

  return (
    <Content>
      <Subheader>
        <div className={classes.backButton}>
          <TitleNav
            title={t('manageUser.manageUser')}
            onClick={() => (state ? history.goBack() : history.push('/'))}
          />
        </div>
      </Subheader>
      {user && (
        <ManageUserIssues
          user={user}
          accountNumber={accountNumber}
          extraInfo={extraInfo}
          liveProducts={liveProducts}
          refetchUser={refetchUser}
        />
      )}
      <Page variant={'sheet'}>
        <ManageUserHeader
          formMethods={formMethods}
          handleUpdateUser={handleUpdateUser}
          isEditing={isEditing}
          setEditing={setEditing}
          setShowDeleteUserDialog={setShowDeleteUserDialog}
          setShowResetPasswordDialog={setShowResetPasswordDialog}
          isLoading={isUserLoading || isUserCountriesLoading}
          user={user}
          tfaData={tfaData?.data}
          isTfaError={isTfaError || tfaData?.status === 204}
          roles={roles}
        />
        <ManageUserRoles
          showAutoRoles={showAutoRoles}
          setShowAutoRoles={setShowAutoRoles}
          accountNumber={accountNumber}
          extraInfo={extraInfo}
          refetchRoles={refetchRoles}
          userId={userId}
          userCountry={user?.country ?? ''}
          roles={roles}
          isLoading={isRolesLoading}
        />
        <Box my={4}>
          <Typography variant="h2" gutterBottom className={classes.sectionHeader}>
            {t('manageUser.inviteUser')}
          </Typography>
          <InviteUserButtons
            data-test-id="invite-buttons-box"
            user={user}
            products={liveProducts}
            refetchUser={refetchUser}
            roles={roles || []}
          />
        </Box>
        {user && (
          <DeleteUserDialog
            showDeleteUserDialog={showDeleteUserDialog}
            setShowDeleteUserDialog={setShowDeleteUserDialog}
            handleDeleteUser={handleDeleteUser}
          />
        )}
        {user && (
          <ResetPasswordDialog
            showResetPasswordDialog={showResetPasswordDialog}
            setShowResetPasswordDialog={setShowResetPasswordDialog}
            handleResetPassword={handleResetPassword}
          />
        )}
      </Page>
    </Content>
  )
}
