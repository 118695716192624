import {Content, Page, ResponsiveTable, Typography, useBreakPoints} from '@hconnect/uikit'
import AddIcon from '@mui/icons-material/Add'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {Alert, Box, Button, Grid, IconButton, Paper} from '@mui/material'
import {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useAllowedHConnectConfigCountries} from '../hooks/useAllowedHConnectConfigCountries'

import {DeleteDialog} from './components/OrderChangeConfigurationsDeleteDialog'
import {useOrderChangeConfigsQuery} from './hooks/useOrderChangeConfigsQuery'
import {useStyles} from './OrderChange.styles'
import {OrderChangeConfig} from './types/OrderChangeConfig'

export const OrderChangeConfigurationsList = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [configurationToDelete, setConfigurationToDelete] = useState<string | null>(null)

  const {classes} = useStyles()

  const handleCreateConfiguration = () => {
    history.push('/configuration/hconnect/order-change/new')
  }

  const handleOpenDeleteDialog = (configurationId: string) => {
    setConfigurationToDelete(configurationId)
    setDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
    setConfigurationToDelete(null)
  }

  const handleRowAction: HandleConfigurationAction = (configurationId, action) => {
    switch (action) {
      case 'edit':
        history.push(`/configuration/hconnect/order-change/${configurationId}/edit`)
        break
      case 'copy':
        history.push(`/configuration/hconnect/order-change/${configurationId}/copy`)
        break
      case 'delete':
        handleOpenDeleteDialog(configurationId)
        break
      default:
        console.error('Unknown action:', action)
    }
  }

  const orderChangeConfigQueryInfo = useOrderChangeConfigsQuery()
  const configurableHConnectCountries = useAllowedHConnectConfigCountries()

  const filteredAndSortedData = useMemo(() => {
    return (
      orderChangeConfigQueryInfo.data
        ?.filter((c) => configurableHConnectCountries.includes(c.countryCode))
        .sort((a, b) => {
          return a.orgUnitId.localeCompare(b.orgUnitId)
        }) ?? []
    )
  }, [orderChangeConfigQueryInfo.data, configurableHConnectCountries])

  return (
    <Content>
      <Page
        data-test-id="page-order-change-configurations"
        title={t('configurations.hconnect.orderChange.title')}
        classNameHeader={classes.pageHeader}
        boxed={false}
        headerActionContent={
          <Button
            data-test-id="create-configuration-button"
            variant="contained"
            onClick={handleCreateConfiguration}
            className={classes.createButton}
            startIcon={<AddIcon />}
            style={{alignContent: 'center', margin: '0 auto', marginTop: isMobile ? '12px' : 0}}
          >
            {t('configurations.hconnect.orderChange.createConfigurationButton')}
          </Button>
        }
        {...(isMobile ? {px: 2, py: 2} : {py: 2})}
      >
        <Grid item md={4} sm={0} xs={0}>
          <Paper elevation={4} style={{padding: isMobile ? '4px 8px' : '26px 36px'}}>
            <ResponsiveTable
              keyField="id"
              columns={OrderChangeConfigurationsRows(handleRowAction)}
              rows={orderChangeConfigQueryInfo.isError ? [] : filteredAndSortedData}
              emptyMessage={
                orderChangeConfigQueryInfo.isError ? (
                  <Alert severity="error">
                    {t('configurations.hconnect.orderChange.errorDataFetching')}
                  </Alert>
                ) : (
                  ''
                )
              }
              tableHeight="70vh"
              onSort={() => ({})}
              loading={orderChangeConfigQueryInfo.isLoading}
              enableHorizontalScroll={true}
              stickyHeader={true}
              enablePinning={true}
              isMobile={isMobile}
              isRowSelectable={isMobile ? undefined : () => true}
            />
          </Paper>
        </Grid>
      </Page>
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        configurationId={configurationToDelete ?? ''}
        confirmMessage={t(
          'configurations.hconnect.orderChange.configurationList.deleteDialogMessage'
        )}
        dialogTitle={t('configurations.hconnect.orderChange.configurationList.deleteDialogTitle')}
      />
    </Content>
  )
}

const OrderChangeConfigurationsRows = (
  handleAction: HandleConfigurationAction
): {field: string; headerName: string; renderCell: (row: OrderChangeConfig) => JSX.Element}[] => {
  const {t} = useTranslation()
  return [
    {
      field: 'action',
      headerName: t('configurations.hconnect.orderChange.configurationList.action'),
      renderCell: (row: OrderChangeConfig) => (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center'
          }}
        >
          <IconButton
            data-test-id={`action-edit-json-${row.id}`}
            onClick={() => handleAction(row.id, 'edit')}
            aria-label="edit"
            style={{boxShadow: 'none'}}
          >
            <ModeEditOutlineIcon style={{color: '#016AD4'}} />
          </IconButton>
          <IconButton
            data-test-id={`action-copy-json-${row.id}`}
            onClick={() => handleAction(row.id, 'copy')}
            style={{boxShadow: 'none'}}
            aria-label="copy"
          >
            <ContentCopyIcon style={{color: '#016AD4'}} />
          </IconButton>
          <IconButton
            data-test-id={`action-delete-json-${row.id}`}
            onClick={() => handleAction(row.id, 'delete')}
            style={{boxShadow: 'none'}}
            aria-label="delete"
          >
            <DeleteIcon style={{color: '#016AD4', fontSize: '24px'}} />
          </IconButton>
        </Box>
      )
    },
    {
      field: 'country',
      headerName: t('configurations.hconnect.orderChange.configurationList.country'),
      renderCell: (row) => <Typography>{row.countryCode}</Typography>
    },
    {
      field: 'orgUnitId',
      headerName: t('configurations.hconnect.orderChange.configurationList.orgUnit'),
      renderCell: (row) => <Typography>{row.orgUnitId}</Typography>
    },
    {
      field: 'businessLine',
      headerName: t('configurations.hconnect.orderChange.configurationList.businessLine'),
      renderCell: (row) => <Typography>{row.businessLine}</Typography>
    },
    {
      field: 'dispatchGroup',
      headerName: t('configurations.hconnect.orderChange.configurationList.dispatchGroup'),
      renderCell: (row) => <Typography>{row.dispatchGroup}</Typography>
    },
    {
      field: 'soldTo',
      headerName: t('configurations.hconnect.orderChange.configurationList.soldTo'),
      renderCell: (row) => <Typography>{row.soldTo}</Typography>
    }
  ]
}

type HandleConfigurationAction = (
  configurationId: string,
  action: 'edit' | 'copy' | 'delete'
) => void
