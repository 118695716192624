import {Site} from '@hconnect/apiclient'
import {Typography} from '@hconnect/uikit'
import {Close} from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {Box, Chip, CircularProgress, TextField} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import clsx from 'clsx'
import React from 'react'

import {useWidgetUserCreationStyles} from '../WidgetUserCreation.styles'

type WidgetDestinationInputProps = {
  isSitesLoading: boolean
  sites: Site[]
  setSelectedSitesIds: (sitesIds: string[]) => void
}
export const WidgetDestinationInput = ({
  isSitesLoading,
  sites,
  setSelectedSitesIds
}: WidgetDestinationInputProps) => {
  const {classes} = useWidgetUserCreationStyles()

  return (
    <Autocomplete
      multiple
      forcePopupIcon={true}
      disableClearable={true}
      popupIcon={
        isSitesLoading ? (
          <CircularProgress size={16} style={{color: '#016AD4'}} />
        ) : (
          <KeyboardArrowDownIcon style={{color: '#00274D'}} />
        )
      }
      className={clsx(classes.autocomplete, {[classes.autocompleteDisabled]: sites.length === 0})}
      loading={isSitesLoading}
      style={{marginTop: '12px'}}
      disabled={sites.length === 0}
      onChange={(_, value) => {
        setSelectedSitesIds(value.map((site) => site.siteId))
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          data-test-id={'select-destination'}
          label={'Select Destination (optional)'}
          style={{color: '#54708C'}}
          focused={true}
          InputLabelProps={{shrink: true}}
        />
      )}
      getOptionLabel={(option) => `${option.siteId}-${option.siteName}-${option.street}`}
      options={sites}
      renderOption={(props, option) => (
        <Box
          {...props}
          component="li"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}
          key={`${option.siteId}-${option.siteName}-${option.street}`}
        >
          <Typography>{`Site: ${option.siteName}`}</Typography>
          <Typography>{`City: ${option.city}`}</Typography>
          <Typography>{`Street: ${option.street}`}</Typography>
        </Box>
      )}
      renderTags={(value: Site[], getTagProps) => (
        <Box style={{display: 'flex', flexDirection: 'column'}}>
          {value.map((option: Site, index: number) => (
            <Chip
              key={index}
              variant="outlined"
              className={classes.autocompleteTags}
              label={
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#FFFFFF',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '300px',
                    lineHeight: '16px'
                  }}
                >
                  {option.siteName}
                </Typography>
              }
              onDelete={(event) => getTagProps({index}).onDelete(event)}
              deleteIcon={<Close style={{color: '#FFFFFF', fontSize: '16px'}} />}
            />
          ))}
        </Box>
      )}
    />
  )
}
