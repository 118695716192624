import {useMutation, useQueryClient} from '@tanstack/react-query'
import {t} from 'i18next'
import {useSnackbar} from 'notistack'

import {api} from '../../../../../App.store'
import {OrderChangeConfig} from '../types/OrderChangeConfig'

import {OrderChangeConfigQueryKey, ProductConfigsQueryKey} from './queryKeys'

type OrderChangeCreateConfig = Omit<OrderChangeConfig, 'id'>

const deleteOrderChangeConfig = async (configId: string) => {
  console.log('DELTETEEEE', configId)
  await api.delete<OrderChangeConfig[]>(`product-configs/order-change-settings/${configId}`)
  return configId
}

//TODO: use OrderChangeCreateConfig instead of OrderChangeConfig
const createOrderChangeConfig = async (orderChangeConfig: OrderChangeConfig) => {
  const {id: _, ...orderChangeConfigWithoutId} = orderChangeConfig
  await api.post<OrderChangeConfig[]>(
    '/product-configs/order-change-settings',
    orderChangeConfigWithoutId
  )
  return orderChangeConfig
}

const editOrderChangeConfig = async (orderChangeConfig: OrderChangeConfig) => {
  await api.put<OrderChangeConfig[]>('/product-configs/order-change-settings', orderChangeConfig)
  return orderChangeConfig
}
type MutationType = 'create' | 'edit' | 'delete'

// TODO: unify into 1 hook with a parameter for the mutation type + make it properly typed
export const useOrderChangeConfigsCreateMutation = () => {
  const queryClient = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation(
    createOrderChangeConfig,
    getMutationHandlers(queryClient, enqueueSnackbar, 'create')
  )
}
export const useOrderChangeConfigsEditMutation = () => {
  const queryClient = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation(
    editOrderChangeConfig,
    getMutationHandlers(queryClient, enqueueSnackbar, 'edit')
  )
}
export const useOrderChangeConfigsDeleteMutation = () => {
  const queryClient = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation(
    deleteOrderChangeConfig,
    getMutationHandlers(queryClient, enqueueSnackbar, 'delete')
  )
}

const getMutationHandlers = (queryClient, enqueueSnackbar, mutationType: MutationType) => ({
  onSuccess: () => {
    const successMessageKey = {
      create: 'configurations.hconnect.orderChange.configurationList.createSuccess',
      edit: 'configurations.hconnect.orderChange.configurationList.editSuccess',
      delete: 'configurations.hconnect.orderChange.configurationList.deleteSuccess'
    }[mutationType]

    enqueueSnackbar(t(successMessageKey), {variant: 'success'})
    queryClient.invalidateQueries([ProductConfigsQueryKey, OrderChangeConfigQueryKey])
  },
  onError: (error: any) => {
    const errorMessageKey = {
      create: 'configurations.hconnect.orderChange.configurationList.createError',
      edit: 'configurations.hconnect.orderChange.configurationList.editError',
      delete: 'configurations.hconnect.orderChange.configurationList.deleteError'
    }[mutationType]

    enqueueSnackbar(t(errorMessageKey), {variant: 'error'})
    console.error('Mutation error:', error)
  }
})
