import {QueryFunction, useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {api} from '../../../../../App.store'
import {OrderChangeConfigOptions} from '../types/OrderChangeConfig'

import {OrderChangeConfigFieldsQueryKey, ProductConfigsQueryKey} from './queryKeys'

type QueryKey = [string, string]

const orderChangeConfigurationFieldsFetcher: QueryFunction<OrderChangeConfigOptions> = async ({
  queryKey
}) => {
  // TODO: once API is ready
  // const [base, endpoint] = queryKey

  const response = await api.get<OrderChangeConfigOptions>(
    '/product-configs/order-change-settings/options'
  )
  return response.data
}

export const useOrderChangeConfigurationOptionsQuery = () => {
  return useQuery<OrderChangeConfigOptions, AxiosError, OrderChangeConfigOptions, QueryKey>(
    [ProductConfigsQueryKey, OrderChangeConfigFieldsQueryKey],
    orderChangeConfigurationFieldsFetcher,
    {
      onError: (error) => {
        console.error(error)
        // TODO: analytics
        // trackEvent('adminConsoleError', {
        //   product: 'adminConsole',
        //   date: new Date().toISOString(),
        //   errorCode: error.response?.status,
        //   component: 'useOrderChangeConfigFieldsQuery',
        // })
      }
    }
  )
}

export const useOrderChangeConfigFieldsQuery = () => {
  const queryInfo = useOrderChangeConfigurationOptionsQuery()

  const {...rest} = queryInfo

  return {
    ...rest,
    data: queryInfo?.data
  }
}
