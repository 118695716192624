import {CreationChannel, Product, User} from '@hconnect/apiclient'
import {useSites} from '@hconnect/common/hooks/useSites'
import {LoadingButton, Typography} from '@hconnect/uikit'
import {Box, Button} from '@mui/material'
import {isEmpty} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {useUserRoles} from '../../common/hooks/useUserRoles'
import {selectLoggedInUserProfile} from '../../modules/LoggedInUser.selectors'
import {RoleAssignment, RoleType} from '../../modules/ManageUsers.selectors'

import {WidgetBusinessLineInput} from './components/WidgetBusinessLineInput'
import {WidgetCountryInput} from './components/WidgetCountryInput'
import {WidgetCustomerInput} from './components/WidgetCustomerInput'
import {WidgetDestinationInput} from './components/WidgetDestinationInput'
import {WidgetEmailInput} from './components/WidgetEmailInput'
import {WidgetMobileNumberInput} from './components/WidgetMobileNumberInput'
import {WidgetNameInput} from './components/WidgetNameInput'
import {WidgetOrgUnitInput} from './components/WidgetOrgUnitInput'
import {WidgetPermissionInput} from './components/WidgetPermissionInput'
import {WidgetProductInput} from './components/WidgetProductInput'
import {InviteUserPayload, useCreateWidgetUser} from './hooks/useCreateWidgetUser'
import {customerRoleSetup} from './utils/CustomerRoleSetup'
import {useWidgetUserCreationStyles} from './WidgetUserCreation.styles'
import {
  CustomerToggleBasedPermissionKeys,
  CustomerToggleBasedPermissions,
  DataScopeOption
} from './WidgetUserCreation.types'

export type WidgetUserCreationFormData = {
  name: string
  email: string
  mobileNumber: string
  customerId: string
  businessLine: string
  country: string
  orgUnit: string
  products: Product[]
  permissions: CustomerToggleBasedPermissions
}

export const WidgetUserCreationForm = () => {
  const {t} = useTranslation()
  const {classes} = useWidgetUserCreationStyles()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const history = useHistory()

  const [cscData, setCscData] = useState<RoleAssignment[]>()
  const cscCountries = cscData
    ?.map((data) => data.dataScope['countryId'])
    .filter((country, index, countries) => countries.indexOf(country) === index) as
    | string[]
    | undefined

  const cscBusinessLines = cscData
    ?.map((data) => data.dataScope['businessLine'])
    .filter(
      (businessLine, index, businessLines) => businessLines.indexOf(businessLine) === index
    ) as string[] | undefined
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | undefined>()
  const [selectedCustomerNumber, setSelectedCustomerNumber] = useState<string | undefined>()

  const [selectedOrgUnit, setSelectedOrgUnit] = useState<DataScopeOption>()
  const [selectedProducts, setSelectedProducts] = useState<Product[]>()
  const [selectedSitesIds, setSelectedSitesIds] = useState<string[]>()

  const [selectedPermissions, setSelectedPermissions] = useState<CustomerToggleBasedPermissions>({
    [CustomerToggleBasedPermissionKeys.canSeeOrderAndDeliveries]: true,
    [CustomerToggleBasedPermissionKeys.canCreateAndChangeOrders]: false,
    [CustomerToggleBasedPermissionKeys.canSeeInvoices]: false
  })

  const formMethods = useForm<WidgetUserCreationFormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      mobileNumber: '',
      customerId: '',
      orgUnit: '',
      businessLine: '',
      country: '',
      products: [],
      permissions: selectedPermissions
    }
  })

  const {handleCreateUser, isLoading: isLoadingCreateUser} = useCreateWidgetUser(formMethods)

  const {data: roles} = useUserRoles(loggedInUserProfile?.user_id)

  const {sites, isSitesLoading} = useSites(selectedCustomerId, [], !!selectedCustomerId)

  useEffect(() => {
    if (roles && roles?.length > 0) {
      const cscRoles = roles?.filter(
        (role) => role.roleType === 'CUSTOMER_SERVICE_CENTER' || role.roleType === 'SALES_AGENT'
      )
      setCscData(cscRoles)
    }
  }, [roles])

  useEffect(() => {
    if (selectedCustomerId && !selectedCustomerNumber) {
      formMethods.resetField('customerId', {defaultValue: ''})
      setSelectedCustomerId(undefined)
    }
  }, [selectedCustomerNumber])

  useEffect(() => {
    if (selectedOrgUnit && selectedCustomerNumber) {
      formMethods.resetField('customerId', {defaultValue: ''})
      setSelectedCustomerNumber(undefined)
    }
  }, [selectedOrgUnit])

  useEffect(() => {
    if (selectedProducts !== undefined) {
      formMethods.setValue('products', selectedProducts)
      if (selectedProducts.find((product) => product === Product.Hub)) {
        void formMethods.trigger('email')
        void formMethods.clearErrors('mobileNumber')
      }

      if (selectedProducts.find((product) => product === Product.OnSite)) {
        void formMethods.trigger('mobileNumber')
        void formMethods.clearErrors('email')
      }

      if (selectedProducts.length === 0) {
        void formMethods.trigger('email')
        void formMethods.trigger('mobileNumber')
      }
    }
  }, [selectedProducts])

  useEffect(() => {
    formMethods.setValue('permissions', selectedPermissions)
  }, [selectedPermissions])

  const products = formMethods.watch('products')
  const selectedCountry = formMethods.watch('country')
  const selectedBusinessLine = formMethods.watch('businessLine')

  const handleSubmitForm = async (data: WidgetUserCreationFormData) => {
    const userData: Partial<User> = {
      name: data.name,
      eMail: data.email,
      mobileNumber: data.mobileNumber,
      country: cscCountries && cscCountries?.length > 1 ? data.country : cscCountries?.[0] || '',
      creationProduct: 'HConnect',
      isTermsApprovalRequired: true,
      creationChannel: 'portalRegistration' as CreationChannel
    }

    const assignUserRolesData: Omit<RoleAssignment, 'userId'>[] = customerRoleSetup(
      data.permissions
    ).map((roleType) => ({
      id: -1,
      roleType: roleType as RoleType,
      dataScope: {
        countryId:
          cscCountries && cscCountries?.length > 1 ? data.country : cscCountries?.[0] || '',
        businessLine:
          cscBusinessLines && cscBusinessLines?.length > 1
            ? data.businessLine
            : cscBusinessLines?.[0] || '',
        orgUnitId: selectedOrgUnit?.value || '',
        customerIds: [selectedCustomerId || ''],
        ...(selectedSitesIds && selectedSitesIds?.length > 0 && {siteIds: selectedSitesIds})
      }
    }))

    const inviteUserData: Omit<InviteUserPayload, 'user_id'>[] = data.products.map((product) => ({
      product
    }))

    await handleCreateUser({
      createUser: userData,
      assignUserRoles: assignUserRolesData,
      inviteUser: inviteUserData
    })
  }

  return (
    <form onSubmit={formMethods.handleSubmit(handleSubmitForm)}>
      <WidgetNameInput formMethods={formMethods} />
      <WidgetEmailInput formMethods={formMethods} selectedProducts={selectedProducts} />
      <WidgetMobileNumberInput formMethods={formMethods} selectedProducts={selectedProducts} />
      {cscCountries && cscCountries?.length > 1 && (
        <WidgetCountryInput formMethods={formMethods} countryCodes={cscCountries} />
      )}
      {cscBusinessLines && cscBusinessLines?.length > 1 && (
        <WidgetBusinessLineInput
          formMethods={formMethods}
          disabled={cscCountries && cscCountries?.length > 1 ? !selectedCountry : false}
        />
      )}
      <WidgetOrgUnitInput
        formMethods={formMethods}
        cscData={cscData}
        selectedOrgUnit={selectedOrgUnit}
        setSelectedOrgUnit={setSelectedOrgUnit}
        disabled={cscBusinessLines && cscBusinessLines?.length > 1 ? !selectedBusinessLine : false}
      />
      <WidgetCustomerInput
        formMethods={formMethods}
        selectedCustomerNumber={selectedCustomerNumber}
        setSelectedCustomerNumber={setSelectedCustomerNumber}
        selectedOrgUnit={selectedOrgUnit}
        cscData={cscData}
        setSelectedCustomerId={setSelectedCustomerId}
      />
      <WidgetDestinationInput
        isSitesLoading={isSitesLoading}
        sites={sites}
        setSelectedSitesIds={setSelectedSitesIds}
      />
      <Typography
        style={{fontSize: '18px', fontWeight: 600, marginBottom: '24px', marginTop: '48px'}}
      >
        {t('widgetUserCreation.form.products.title')}
      </Typography>
      <Box style={{display: 'flex', flexDirection: 'column'}}>
        <WidgetProductInput
          product={Product.Hub}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
        <WidgetProductInput
          product={Product.OnSite}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
      </Box>
      <Typography
        style={{fontSize: '18px', fontWeight: 600, marginBottom: '24px', marginTop: '48px'}}
      >
        {t('widgetUserCreation.form.permissions')}
      </Typography>
      <Box style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
        <WidgetPermissionInput
          permissionKey={CustomerToggleBasedPermissionKeys.canSeeOrderAndDeliveries}
          selectedPermissions={selectedPermissions}
          setSelectedPermissions={setSelectedPermissions}
          defaultValue={true}
          disabled={true}
        />
        <WidgetPermissionInput
          permissionKey={CustomerToggleBasedPermissionKeys.canCreateAndChangeOrders}
          selectedPermissions={selectedPermissions}
          setSelectedPermissions={setSelectedPermissions}
        />
        <WidgetPermissionInput
          permissionKey={CustomerToggleBasedPermissionKeys.canSeeInvoices}
          selectedPermissions={selectedPermissions}
          setSelectedPermissions={setSelectedPermissions}
        />
      </Box>
      <Box style={{display: 'flex', gap: '12px', justifyContent: 'space-between'}}>
        <Button
          className={classes.discardButton}
          variant="text"
          onClick={() => history.push('/widgetUserList')}
        >
          {t('widgetUserCreation.form.goBackToSearch')}
        </Button>
        <LoadingButton
          type="submit"
          btnClassName={classes.submitButton}
          progressClassName={classes.progress}
          loading={isLoadingCreateUser}
          disabled={
            isEmpty(formMethods.formState.dirtyFields) ||
            !isEmpty(formMethods.formState.errors) ||
            !formMethods.formState.isValid ||
            products.length === 0 ||
            !selectedCustomerId
          }
        >
          <Typography
            style={{
              textTransform: 'none',
              fontSize: '16px',
              fontWeight: 500,
              letterSpacing: 0,
              color: '#FFFFFF'
            }}
          >
            {t('widgetUserCreation.form.create')}
          </Typography>
        </LoadingButton>
      </Box>
    </form>
  )
}
