import {useState} from 'react'

import {OrderChangeConfig, OrderChangeFieldSetting} from '../types/OrderChangeConfig'

const empty = {
  countryCode: '',
  businessLine: '',
  orgUnitId: '',
  dispatchGroup: '',
  soldTo: '',
  fieldSettings: [] as OrderChangeFieldSetting[],
  id: ''
}

export const useOrderChangeForm = (defaultConfig: OrderChangeConfig | null) => {
  const [formState, setFormState] = useState<OrderChangeConfig>(defaultConfig || empty)

  return {
    formState,
    setFormState
  }
}
