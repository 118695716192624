import {useTranslation} from '@hconnect/uikit'
import {useState} from 'react'
import {useHistory} from 'react-router-dom'

import {OrderChangeConfigurationDetails} from './components/OrderChangeConfigurationDetails'
import {useOrderChangeConfigsCreateMutation} from './hooks/useOrderChangeConfigsMutations'
import {useOrderChangeConfigFieldsQuery} from './hooks/useOrderChangeConfigurationOptions'
import {useOrderChangeForm} from './hooks/useOrderChangeForm'

export const OrderChangeConfigurationCreate = () => {
  const {formState: initialFormState} = useOrderChangeForm(null)
  const history = useHistory()
  const [formState, setFormState] = useState(initialFormState)
  const orderChangeConfigsMutation = useOrderChangeConfigsCreateMutation()
  const {t} = useTranslation()

  const handleCreateButtonClick = async () => {
    try {
      await orderChangeConfigsMutation.mutateAsync(formState)
    } finally {
      history.goBack()
    }
  }

  const orderChangeConfigFieldsQueryInfo = useOrderChangeConfigFieldsQuery()

  if (orderChangeConfigFieldsQueryInfo.isLoading || !orderChangeConfigFieldsQueryInfo.data) {
    return <div>{t('configurations.hconnect.orderChange.configurationDetails.loading')}</div>
  }

  return (
    <OrderChangeConfigurationDetails
      mode="create"
      onSaveButtonClick={handleCreateButtonClick}
      formState={formState}
      setFormState={setFormState}
      configOptions={orderChangeConfigFieldsQueryInfo.data}
      isSubmitting={orderChangeConfigsMutation.isLoading}
    />
  )
}
