import {Item} from '@hconnect/common/Invite/Create/components/LookupDropdown'
import {ErrorPaper, SelectDropdown} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React, {useEffect} from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {useBusinessLines} from '../../../common/hooks/useBusinessLines'
import {selectLoggedInUserProfile} from '../../../modules/LoggedInUser.selectors'
import {useWidgetUserCreationStyles} from '../WidgetUserCreation.styles'

import {WidgetInputsProps} from './WidgetInputs.types'

type WidgetBusinessLineInputProps = WidgetInputsProps & {
  disabled?: boolean
}
export const WidgetBusinessLineInput = ({formMethods, disabled}: WidgetBusinessLineInputProps) => {
  const {t} = useTranslation()
  const {classes} = useWidgetUserCreationStyles()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const selectedCountry = formMethods.watch('country')

  const {data: businessLineOptions, isLoading: isLoadingBusinessLines} = useBusinessLines(
    selectedCountry || loggedInUserProfile?.country || '',
    !!selectedCountry || !!loggedInUserProfile
  )

  useEffect(() => {
    formMethods.resetField('businessLine')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry])

  return (
    <Controller
      name="businessLine"
      control={formMethods.control}
      rules={{
        required: {
          value: true,
          message: t('widgetUserCreation.form.errors.required', {
            fieldName: t('widgetUserCreation.form.businessLine')
          })
        }
      }}
      render={({field}) => (
        <SelectDropdown
          {...field}
          showSearch={false}
          disabled={disabled}
          data-test-id="business-line-dropdown"
          label={t('widgetUserCreation.form.businessLine')}
          className={classes.selectDropdown}
          errorRender={() => <ErrorPaper variant="primaryDark" />}
          selectedProps={{style: {fontWeight: 400}}}
          showError={false}
          loading={isLoadingBusinessLines}
          options={businessLineOptions?.map((businessLine) => ({
            value: businessLine.businessLineCode,
            label: businessLine.businessLineName
          }))}
          renderItem={(item: Item) => (
            <Box style={{display: 'flex', flexDirection: 'column'}}>
              <Box>{item.label}</Box>
              <Box>{item.value}</Box>
            </Box>
          )}
          stringifyItem={(item: Item) => item.value}
          renderChosenItem={(item: Item) => item.label}
          selectedItem={{
            label:
              businessLineOptions?.find((option) => option.businessLineCode === field.value)
                ?.businessLineName || '',
            value:
              businessLineOptions?.find((option) => option.businessLineCode === field.value)
                ?.businessLineCode || ' '
          }}
          popoverStyle={{left: 0}}
          onChange={(item) => {
            field.onChange(item.value)
          }}
        />
      )}
    />
  )
}
