import {Item} from '@hconnect/common/Invite/Create/components/LookupDropdown'
import {ErrorPaper, SelectDropdown} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React, {useEffect} from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useOrgUnit} from '../../../hooks/useOrgUnit'
import {RoleAssignment} from '../../../modules/ManageUsers.selectors'
import {useWidgetUserCreationStyles} from '../WidgetUserCreation.styles'
import {DataScopeOption} from '../WidgetUserCreation.types'

import {WidgetInputsProps} from './WidgetInputs.types'

type WidgetOrgUnitInputProps = WidgetInputsProps & {
  setSelectedOrgUnit: (orgUnit?: DataScopeOption) => void
  disabled: boolean
  selectedOrgUnit?: DataScopeOption
  cscData?: RoleAssignment[]
}
export const WidgetOrgUnitInput = ({
  formMethods,
  selectedOrgUnit,
  setSelectedOrgUnit,
  cscData,
  disabled
}: WidgetOrgUnitInputProps) => {
  const {t} = useTranslation()
  const {classes} = useWidgetUserCreationStyles()
  const selectedBusinessLine = formMethods.watch('businessLine')
  const selectedCountry = formMethods.watch('country')

  const cscCountries = cscData
    ?.map((data) => data.dataScope['countryId'])
    .filter((country, index, countries) => countries.indexOf(country) === index) as
    | string[]
    | undefined

  const cscBusinessLines = cscData
    ?.map((data) => data.dataScope['businessLine'])
    .filter(
      (businessLine, index, businessLines) => businessLines.indexOf(businessLine) === index
    ) as string[] | undefined

  const {
    data: orgUnitsData,
    isLoading: isLoadingOrgUnits,
    isError: isOrgUnitsError
  } = useOrgUnit(
    cscCountries && cscCountries?.length > 1
      ? selectedCountry?.toUpperCase()
      : cscCountries?.[0] || '',
    cscBusinessLines && cscBusinessLines?.length > 1
      ? selectedBusinessLine?.toUpperCase()
      : cscBusinessLines?.[0] || '',
    false
  )

  useEffect(() => {
    setSelectedOrgUnit(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusinessLine])

  return (
    <Controller
      name="orgUnit"
      control={formMethods.control}
      rules={{
        required: {
          value: true,
          message: t('widgetUserCreation.form.errors.required', {
            fieldName: t('widgetUserCreation.form.orgUnit')
          })
        }
      }}
      render={({field}) => (
        <SelectDropdown
          {...field}
          disabled={disabled}
          data-test-id="org-unit-dropdown"
          label={t('widgetUserCreation.form.orgUnit')}
          className={classes.selectDropdown}
          errorRender={() => <ErrorPaper variant="primaryDark" />}
          selectedProps={{style: {fontWeight: 400}}}
          showError={isOrgUnitsError}
          loading={isLoadingOrgUnits}
          options={orgUnitsData?.map((orgUnit) => ({
            value: orgUnit.key,
            label: orgUnit.label
          }))}
          renderItem={(item: Item) => (
            <Box style={{display: 'flex', flexDirection: 'column'}}>
              <Box>{item.label}</Box>
              <Box>{item.value}</Box>
            </Box>
          )}
          stringifyItem={(item: Item) => item.value}
          renderChosenItem={(item: Item) => item.label}
          selectedItem={selectedOrgUnit}
          popoverStyle={{left: 0}}
          onChange={(item) => {
            setSelectedOrgUnit(item)
            field.onChange(item.value)
          }}
        />
      )}
    />
  )
}
