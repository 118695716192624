import {useTranslation} from '@hconnect/uikit'
import {useParams} from 'react-router'
import {useHistory} from 'react-router-dom'

import {OrderChangeConfigurationDetails} from './components/OrderChangeConfigurationDetails'
import {useOrderChangeConfigsEditMutation} from './hooks/useOrderChangeConfigsMutations'
import {useOrderChangeConfigsQuery} from './hooks/useOrderChangeConfigsQuery'
import {useOrderChangeConfigFieldsQuery} from './hooks/useOrderChangeConfigurationOptions'
import {useOrderChangeForm} from './hooks/useOrderChangeForm'

export const OrderChangeConfigurationEdit = () => {
  const {configId} = useParams<{
    configId?: string
  }>()

  const {t} = useTranslation()
  const history = useHistory()
  const orderChangeConfigsMutation = useOrderChangeConfigsEditMutation()

  const orderChangeConfigQueryInfo = useOrderChangeConfigsQuery()
  const orderChangeConfigFieldsQueryInfo = useOrderChangeConfigFieldsQuery()

  const {formState, setFormState} = useOrderChangeForm(
    orderChangeConfigQueryInfo?.data?.find((config) => config.id === configId) ?? null
  )

  const handleSaveButtonClick = async () => {
    try {
      await orderChangeConfigsMutation.mutateAsync(formState)
    } finally {
      history.goBack()
    }
  }

  if (orderChangeConfigFieldsQueryInfo.isLoading || !orderChangeConfigFieldsQueryInfo.data) {
    return <div>{t('configurations.hconnect.orderChange.configurationDetails.loading')}</div>
  }

  return (
    <OrderChangeConfigurationDetails
      mode="edit"
      configId={configId}
      onSaveButtonClick={handleSaveButtonClick}
      formState={formState}
      setFormState={setFormState}
      configOptions={orderChangeConfigFieldsQueryInfo.data}
      isSubmitting={orderChangeConfigsMutation.isLoading}
    />
  )
}
